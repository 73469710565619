<template>
  <div>
    <div class="row">
      <div :class="[scenarioViewGroup==='L' ? 'col-12' : 'col-xs-12 col-sm-6 col-md-4 col-lg-4 third', 'scenario-list']"> 
        <c-table
          ref="table"
          title="시나리오 목록"
          :columns="gridColumns"
          :gridHeight="setheight"
          :data="process.thirdScenarios"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable&&!disabled"
          :isExcelDown="scenarioViewGroup==='L'"
          :isFullScreen="scenarioViewGroup==='L'"
          :customTrClass="setTrClass"
          selection="multiple"
          rowKey="ramThirdAssessScenarioId"
          @table-data-change="tableDataChange"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-update-btn 
                name="updateBtn"
                :data="updateBtnData"
                :btnEditable="btnEditable"
                :flagCondition="flagCondition"
                @back="back"
              />
              <!-- 이전평가결과조회 -->
              <c-btn 
                v-show="isCompared" 
                label="LBL0001166" 
                icon="compare_arrows" 
                @btnClicked="compareScenario" />
              <c-btn v-if="editable&&!disabled" label="LBLREMOVE" icon="remove" @btnClicked="removeScenario" />
              <c-btn 
                v-show="editable&&!disabled&&!btnEditable" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="process"
                mappingType="PUT"
                label="평가완료" 
                icon="check"
                @beforeAction="completeProcess"
                @btnCallback="completeProcessCallback" />
              <c-btn 
                v-show="editable&&!disabled&&scenarioViewGroup==='L'&&process.thirdScenarios&&process.thirdScenarios.length>0" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="process.thirdScenarios"
                mappingType="PUT"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveScenario"
                @btnCallback="saveScenarioCallback" />
            </q-btn-group>
          </template>
          <template v-slot:suffixTitle>
            <q-btn-group outline class="scenario-view-group">
              <q-btn 
                :outline="scenarioViewGroup!=='L'"
                :color="scenarioViewGroup==='L' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="view_list"
                @click="listView"
              >
                <q-tooltip>
                  <!-- 목록형 -->
                  {{$label('LBL0001167')}}
                </q-tooltip>
              </q-btn>
              <q-btn 
                :outline="scenarioViewGroup!=='C'"
                :color="scenarioViewGroup==='C' ? 'indigo' : 'grey'" 
                size="11px" label="" icon="space_dashboard"
                @click="cardView"
              >
                <q-tooltip>
                  <!-- 카드형 -->
                  {{$label('LBL0001168')}}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && Boolean(props.row.ramThirdAssessScenarioId)"
                :requestContentsCols="requestContentsCols"
                tableKey="ramThirdAssessScenarioId"
                ibmTaskTypeCd="ITT0000013"
                ibmTaskUnderTypeCd="ITTU000015"
                @imprChange="imprChange"
              />
            </template>
            <template v-else-if="col.name==='item'">
              <q-item class="card-scenario-list">
                <q-item-section>
                  <q-item-label class="scenario-card-title">{{`${props.row['riskHazardQuestionName']}`}}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon name="arrow_forward_ios" class="text-primary"
                  style="font-size: 20px;"
                  @click="getDetail(props.row)"
                  ></q-icon>
                </q-item-section>
              </q-item>
            </template>
            <template v-else-if="col.name==='riskHazardPicture'">
              <q-icon
                style="font-size: 30px; cursor:pointer;"
                class="text-success" 
                name="image"
                @click.stop="openHazardPicture(props)" /> 
              <!-- <c-upload-picture
                class="scenario4m"
                height="35px"
                :attachInfo="{
                  isSubmit: '',
                  taskClassCd: 'THIRD_RISK_HAZARD',
                  taskKey: props.row.ramThirdAssessScenarioId,
                }"
                :editable="editable&&!disabled&&selectScenario">
              </c-upload-picture> -->
            </template>
            <template v-else-if="col.name==='picture'">
              <q-icon
                style="font-size: 30px; cursor:pointer;"
                class="text-primary" 
                name="image"
                @click.stop="openImprPicture(props)" />
            </template>
          </template>
        </c-table>
      </div>
      <div v-if="scenarioViewGroup==='C'" class="col-xs-12 col-sm-6 col-md-8 col-lg-8 scenario-card">
        <q-form ref="editForm">
          <div class="row">
            <div class="col-5">
              <!-- 상세정보 -->
              <c-card title="LBL0001169" class="cardClassDetailForm no-margin">
                <template slot="card-button">
                  <q-btn-group outline >
                    <!-- 저장 -->
                    <c-btn 
                      v-show="editable && !disabled" 
                      :isSubmit="isSaveUnit"
                      :url="saveUnitUrl"
                      :param="scenario"
                      mappingType="PUT"
                      label="LBLSAVE" 
                      icon="save"
                      @beforeAction="saveScenarioUnit"
                      @btnCallback="saveScenarioUnitCallback" />
                  </q-btn-group>
                </template>
                <template slot="card-detail">
                  <div class="col-12">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="250px"
                      :attachInfo="attachHazardInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-12">
                    <!-- 유해위험요인(위험한 상황과 사건) -->
                    <c-textarea
                      :disabled="true"
                      :editable="editable"
                      label="LBL0001193"
                      name="riskHazardQuestionName"
                      v-model="scenario.riskHazardQuestionName">
                    </c-textarea>
                  </div>
                  <div class="col-6">
                    <!-- 관련법규 -->
                    <c-text
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001021"
                      name="relationLaw"
                      v-model="scenario.relationLaw">
                    </c-text>
                  </div>
                  <div class="col-6">
                    <!-- 위험등록부 -->
                    <c-checkbox
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :isFlag="true"
                      label="LBL0001171"
                      name="riskbookFlag"
                      v-model="scenario.riskbookFlag"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가일 -->
                    <c-datepicker
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      label="LBL0001013"
                      name="assessDate"
                      v-model="scenario.assessDate"
                    />
                  </div>
                  <div class="col-6">
                    <!-- 평가자 -->
                    <c-field
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :data="scenario"
                      label="LBL0001014"
                      name="assessUserId"
                      v-model="scenario.assessUserId"
                      @username="(val) => { scenario.assessUserName = val }" />
                  </div>
                </template>
              </c-card>
            </div>
            <div class="col-7">
              <!-- 개선정보 -->
              <c-card title="LBL0001172" class="cardClassDetailForm no-margin">
                <template slot="card-detail">
                  <div class="col-4">
                    <!-- 사진 -->
                    <c-upload-picture
                      height="170px"
                      :attachInfo="attachBeforeInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-8">
                    <div class="row">
                      <div class="col-12">
                        <!-- 현재안전조치 -->
                        <c-textarea
                          :disabled="disabled||!selectScenario"
                          :editable="editable"
                          :rows="3"
                          label="LBL0001025"
                          name="currentSafetyMeasures"
                          v-model="scenario.currentSafetyMeasures">
                        </c-textarea>
                      </div>
                      <div class="col-12" style="position:relative; top:-20px;">
                        <!-- 판단결과 -->
                        <c-select
                          :disabled="disabled||!selectScenario"
                          :editable="editable"
                          :comboItems="riskStandardItems"
                          type="edit"
                          itemText="ramRiskLevelName"
                          itemValue="ramRiskThirdStandardId"
                          label="LBL0001151"
                          name="ramRiskThirdStandardId"
                          v-model="scenario.ramRiskThirdStandardId"
                        ></c-select>
                      </div>
                    </div>
                  </div>
                  <div class="col-12" style="position:relative; top:-40px;">
                    <!-- 개선 목록 -->
                    <c-table
                      title="LBL0001174"
                      :editable="editable&&!disabled&&selectScenario"
                      :columns="gridImpr.columns"
                      :data="scenario.imprs"
                      :gridHeight="gridImpr.height"
                      :usePaging="false"
                      :columnSetting="false"
                      :isFullScreen="false"
                      :filtering="false"
                      :isExcelDown="false"
                      @linkClick="(row) => linkClick({ sopImprovementId: row.sopImprovementId }, row)"
                    >
                      <template slot="table-button">
                        <q-btn-group outline>
                          <!-- 개선요청 -->
                          <c-btn 
                            v-if="editable&&!disabled&&selectScenario" 
                            label="LBLIMPRREQUEST" 
                            icon="add" 
                            @btnClicked="openImprRequest" />
                        </q-btn-group>
                      </template>
                    </c-table>
                  </div>
                  <div class="col-4" style="position:relative; top:-30px;">
                    <!-- 사진 -->
                    <c-upload-picture
                      isMultiTask
                      :attachInfo="attachAfterInfo"
                      :editable="editable&&!disabled&&selectScenario">
                    </c-upload-picture>
                  </div>
                  <div class="col-8" style="position:relative; top:-30px;">
                    <!-- 추가 리스크관리 계획 -->
                    <c-textarea
                      :disabled="disabled||!selectScenario"
                      :editable="editable"
                      :rows="3"
                      label="LBL0001122"
                      name="improvementMeasures"
                      v-model="scenario.improvementMeasures">
                    </c-textarea>
                  </div>
                </template>
              </c-card>
            </div>
          </div>
        </q-form>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <c-win-pop 
      ref="winPopup"  
      @onClose="val=>evtCloseWinPopup(val)"  
      @onRecvEvtFromWinPop="val=>onRecvWinPop(val)" />
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import CTextarea from '../../../../components/CTextarea.vue';

export default {
  components: { CTextarea },

  name: 'thirdScenario',
  props: {
    process: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정명
        ramProcessAssessStepCd: '',  // 평가진행상태
        processExplainFeature: '',  // 공정 특징 설명
        assessDeptCd: '',  // 평가 부서 코드
        assessUserId: '',  // 평가 담당자
        assessUserName: '',  // 평가 담당자
        ramMatrixId: '',
        thirdScenarios: [],
        reCheckedCnt:0,
      }),
    },
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        ramStepCd: '',
        ramTechniqueCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
    planUpdateBtnData: {
      title: 'LBLPLAN', // 계획
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      grid: {
        data: [],
      },
      gridImpr: {
        columns: [
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: 'LBLTITLE',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'ibmStepName',
            field: 'ibmStepName',
            label: 'LBLSTEPPROGRESS',
            align: 'center',
            style: 'width:85px',
            sortable: true
          },
          {
            name: 'improveRequestDeptName',
            field: 'improveRequestDeptName',
            // 요청부서
            label: 'LBL0000927',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            // 조치부서
            label: 'LBL0001018',
            align: 'center',
            style: 'width:85px',
            sortable: true,
          },
        ],
        height: '260px'
      },
      scenario: {
        ramThirdAssessScenarioId: '',  // 시나리오 일련번호_시나리오의 한 라인(원일별)
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        processCd: '',  // 단위공정 코드
        processName: '',  // 단위공정
        riskHazardQuestionName: '',  // 유해위험요인 질문
        relationLaw: '',  // 관련법규
        currentSafetyMeasures: '',  // 현재 안전조치
        ramRiskThirdStandardId: null,  // 3단계 판단결과
        riskColor: '',  // 위험색상
        improvementMeasures: '',  // 개선대책
        assessDate: '',  // 평가일
        assessUserId: '',  // 평가자 ID
        compareId: '',  // 비교 대상 일련번호(시나리오 일련번호)
        recheckFlag: '',  // 재검토 여부
        regUserId: '',  // 작성자 ID
        chgUserId: '',  // 수정자 ID
        imprs: [], // 개선목록
      },
      updateBtnData: {
        title: 'LBL0001164', // 평가
        flag: false,
        research: '',
      },
      attachHazardInfo: {
        isSubmit: '',
        taskClassCd: 'THIRD_RISK_HAZARD',
        taskKey: '',
      },
      attachBeforeInfo: {
        isSubmit: '',
        taskClassCd: 'THIRD_SCENARIO_BEFORE',
        taskKey: '',
      },
      attachAfterInfo: {
        isSubmit: '',
        task: []
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      activeWinProps: {
        processCd: '',
        ramThirdAssessScenarioId: '',
      },
      scenarioViewGroup: 'L',
      riskStandardItems: [],
      editable: true,
      isSave: false,
      isSaveUnit: false,
      isComplete: false,
      listUrl: '',
      detailUrl: '',
      listImprUrl: '',
      listRiskStandardUrl: '',
      saveUrl: '',
      saveUnitUrl: '',
      deleteUrl: '',
      completeUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    window.addEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$refs.winPopup.closeWinPop()
    window.removeEventListener('beforeunload', () => {
      this.$refs.winPopup.closeWinPop()
    });
  },
  computed: {
    isCompared() {
      return this.process.reCheckedCnt > 0;
    },
    disabled() {
      return (!(this.param.ramStepCd === 'RRS0000005' || this.param.ramStepCd == 'RRS0000010') || this.process.ramProcessAssessStepCd === 'RPA0000005')
        && !this.updateBtnData.flag
    },
    setheight() {
      let pxRemove = this.height.replace('px', '');
      return this.height && this.height.indexOf('px') > -1 && !isNaN(pxRemove) ? (Number(pxRemove) - 80) + 'px' : '500px'
    },
    btnEditable() {
      return this.editable && this.param.ramStepCd === 'RRS0000010' && this.process.ramProcessAssessStepCd === 'RPA0000005'
    },
    flagCondition() {
      return this.param.ramStepCd === 'RRS0000010'
    },
    gridColumns() {
      let cols = [];
      if (this.scenarioViewGroup === 'L') {
        cols = [
          {
            fix: true,
            name: 'riskHazardQuestionName',
            field: 'riskHazardQuestionName',
            label: '유해위험요인 (위험한 상황과 사건)',
            align: 'left',
            style: 'width:450px',
            sortable: false,
          },
          {
            fix: true,
            name: 'riskHazardPicture',
            field: 'riskHazardPicture',
            // 사진
            label: 'LBL0001175',
            align: 'center',
            style: 'width:40px',
            sortable: false,
            type: 'custom',
          },
          {
            name: 'relationLaw',
            field: 'relationLaw',
            // 관련법규
            label: 'LBL0001021',
            align: 'left',
            style: 'width:130px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'currentSafetyMeasures',
            field: 'currentSafetyMeasures',
            // 현재안전조치
            label: 'LBL0001025',
            align: 'left',
            style: 'width:180px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'ramRiskThirdStandardId',
            field: 'ramRiskThirdStandardId',
            // 판단결과
            label: 'LBL0001151',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            itemText: 'ramRiskLevelName',
            itemValue: 'ramRiskThirdStandardId',
            comboItems: this.riskStandardItems,
            isChip: true,
            required: true,
            setHeader: true,
          },
          {
            name: 'picture',
            field: 'picture',
            label: '개선 전/후 사진',
            align: 'center',
            type: 'custom',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'improvementMeasures',
            field: 'improvementMeasures',
            // 추가 리스크관리 계획
            label: 'LBL0001122',
            align: 'left',
            style: 'width:180px',
            sortable: false,
            type: 'textarea'
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: 'LBLIMPROVE',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            type: 'date',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            type: 'user',
            userId: 'assessUserId',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'riskbookFlag',
            field: 'riskbookFlag',
            // 위험<br/>등록부
            label: 'LBL0001187',
            align: 'center',
            style: 'width:60px',
            sortable: true,
            type: 'check',
            'true': 'Y',
            'false': 'N',
          },
        ]
      } else {
        cols = [
          {
            name: 'item',
            field: 'item',
            // 평가내용
            label: 'LBL0001179',
            align: 'left',
            sortable: false,
            type: 'custom'
          },
        ]
      }
      return cols;
    },
    selectScenario() {
      return Boolean(this.scenario.ramThirdAssessScenarioId)
    },
    requestContentsCols() {
      return ['riskHazardQuestionName'];
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.third.scenario.list.url
      this.detailUrl = selectConfig.ram.third.scenario.get.url
      this.listImprUrl = selectConfig.ram.third.riskReduce.impr.url
      this.listRiskStandardUrl = selectConfig.ram.riskThirdStandard.list.url
      this.saveUrl = transactionConfig.ram.third.scenario.save.url
      this.saveUnitUrl = transactionConfig.ram.third.scenario.saveUnit.url
      this.deleteUrl = transactionConfig.ram.third.scenario.delete.url
      this.completeUrl = transactionConfig.ram.assessProcess.complete.url;
      // code setting
      // list setting 
      this.getRiskStandardItems();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramRiskAssessmentPlanId: this.process.ramRiskAssessmentPlanId,
        processCd: this.process.processCd,
      }
      this.$http.request((_result) => {
        this.process.thirdScenarios = _result.data;
      },);
    },
    getRiskStandardItems() {
      this.$http.url = this.listRiskStandardUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.process.plantCd,
      }
      this.$http.request((_result) => {
        this.riskStandardItems = this.$_.map(_result.data, item => {
          return this.$_.extend(item, {
            attrVal1: item.riskColor
          })
        });
      },);
    },
    getDetail(row) {
      this.scenario = row
      this.$http.url = this.$format(this.detailUrl, row.ramThirdAssessScenarioId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.scenario, _result.data);

        this.$set(this.attachHazardInfo, 'taskKey', this.scenario.ramThirdAssessScenarioId)
        this.$set(this.attachBeforeInfo, 'taskKey', this.scenario.ramThirdAssessScenarioId)
        this.$set(this.attachAfterInfo, 'task', this.$_.map(this.scenario.imprs, impr => {
          return {
            taskClassCd: 'IBM_AFTER',
            taskKey: impr.sopImprovementId,
          }
        }))
      },);
    },
    getImprs() {
      this.$http.url = this.listImprUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        ramThirdAssessScenarioId: this.scenario.ramThirdAssessScenarioId,
      }
      this.$http.request((_result) => {
        this.$set(this.scenario, 'imprs', _result.data)
      },);
    },
    tableDataChange(props, col) {
      if (col.name === 'ramRiskThirdStandardId') {
        this.$set(props.row, 'riskColor', this.$_.find(this.riskStandardItems, { ramRiskThirdStandardId: props.row.ramRiskThirdStandardId }).riskColor)
      }
    },
    research() {
      this.$emit('research', 'scenario')
    },
    /* eslint-disable no-unused-vars */
    imprChange(data) {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      this.research();
    },
    openImprRequest() {
      let requestContents = this.scenario.riskHazardQuestionName
      
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: this.scenario.ramThirdAssessScenarioId,
        ibmTaskTypeCd: 'ITT0000013',
        ibmTaskUnderTypeCd: 'ITTU000015',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    closeCardImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getImprs();
    },
    linkClick(data, row) {
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCardImprPopup;
    },
    /* eslint-disable no-unused-vars */
    listView() {
      this.scenarioViewGroup = 'L'
    },
    cardView() {
      this.scenarioViewGroup = 'C'
    },
    saveScenario() {
      if (this.$comm.validTable(this.gridColumns, this.process.thirdScenarios)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveScenarioCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    saveScenarioUnit() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.scenario.chgUserId = this.$store.getters.user.userId

              this.isSaveUnit = !this.isSaveUnit
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveScenarioUnitCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.research();
      this.getList();
    },
    removeScenario() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData),
            };
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.process.thirdScenarios.splice(this.$_.findIndex(this.process.thirdScenarios, item), 1)
              })
              this.$refs['table'].$refs['compo-table'].clearSelection();

              this.planUpdateBtnData.planResearch = uid();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcess() {
      // 도면 별 노드에 시나리오가 하나 이상 등록되었는지?
      // 화면에서 추가는 하고 저장을 하지 않았을 시에 해당 데이터도 일괄 저장 처리
      // 저장 처리 후 callback에서 process 목록 재조회
      let isProgress = true;
      if (!this.process.thirdScenarios || this.process.thirdScenarios.length === 0) {
        isProgress = false
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          // 공정 [' + this.process.processName + ']에 등록된 시나리오가 없습니다.
          message: this.getLangMessage('MSG0000399', {s1: this.process.processName}),
          type: 'warning', // success / info / warning / error
        });
      }

      if (isProgress) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGCOMPLETE', // 완료하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.process.chgUserId = this.$store.getters.user.userId
            this.process.ramProcessAssessStepCd = 'RPA0000005';

            this.isComplete = !this.isComplete
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    completeProcessCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('assessComplete', _result.data)
      
      this.planUpdateBtnData.planResearch = uid();
    },
    openHazardPicture(props) {
      this.popupOptions.title = 'LBL0001194'; // 유해위험요인(위험한 상황과 사건) 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        disabled: this.disabled 
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdRiskHazardPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPicturePopup;
    },
    closeHazardPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    openImprPicture(props) {
      this.popupOptions.title = 'LBL0001181'; // 개선 사진
      this.popupOptions.param = {
        ramThirdAssessScenarioId: props.row.ramThirdAssessScenarioId,
        sopImprovementIds: props.row.sopImprovementIds,
        disabled: this.disabled
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/third/action/thirdScenarioImprPicture.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeScenarioImprPicturePopup;
    },
    closeScenarioImprPicturePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    compareScenario() {
      let uri = `/ram/compare/compareScenario?ramTechniqueCd=${this.param.ramTechniqueCd}&ramRiskAssessmentPlanId=${this.param.ramRiskAssessmentPlanId}&processCd=${this.process.processCd}`;
      this.$refs.winPopup.openWinPop(uri, 1560, 700);
    },
    evtCloseWinPopup() {
      Object.assign(this.$data.activeWinProps, this.$options.data().activeWinProps);
    },
    onRecvWinPop(recvObj) {
      if (recvObj) {
        this.$set(this.activeWinProps, 'processCd', recvObj.processCd)
        this.$set(this.activeWinProps, 'ramThirdAssessScenarioId', recvObj.ramThirdAssessScenarioId)
      }
    },
    setTrClass(props) {
      return props.row.processCd === this.activeWinProps.processCd 
        && props.row.compareId === this.activeWinProps.ramThirdAssessScenarioId ? 'bg-purple-2' : '';
    },
    /**
     * 재조회
     * 목적 : 돌아가기전 데이터를 저장 했을 수도 있지만 
     *        입력만 하고 돌아가는 경우를 대비하기 위함
     */
    back() {
      this.getList();
    },
  }
};
</script>
<style lang="sass">
.scenario-chip i 
  margin-right: -0.2em !important

.scenario-view-group 
  button
    margin: 0 !important

.scenario-card-title
  margin-left: -5px
  font-size: 0.8rem

.scenario-card-side
  text-align: center
  align-self: center
  // flex-basis: 50%

.card-risk-item
  padding-top: 0px
  padding-bottom: 0px
  min-height: auto
.card-risk-info
  width: auto
  min-width: 0
  max-width: 100%
  flex: 10000 1 0%
  display: flex
  border-radius: 10px
  font-size: 1rem
  height: 30px
.before-risk
  background: #3A98B9
  margin-right: 5px
.after-risk
  background: #3A98B9
  margin-left: 5px
.risk-number
  font-size: 1.3rem

.detail-risk
  font-size: 0.9rem
  font-weight: 600
.detail-risk-num
  font-size: 1rem
  font-weight: 700
  color: #e63946

.card-scenario-list
  padding: 0px 16px !important
  min-height: 28px

.third
  table
    .q-virtual-scroll__content
      .q-tr 
        height: 48px !important
</style>
