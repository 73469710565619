<template>
  <div class="row">
    <div class="col-md-12 col-lg-12">
      <c-table
        ref="substanceTable"
        title="구성성분 목록"
        tableId="substanceTable"
        :columns="grid.columns"
        :data="grid.data"
        :columnSetting="false"
        selection="multiple"
        rowKey="mdmChemId"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        gridHeight="350px"
        :editable="editable"
        checkDisableColumn="noFlag"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-checkbox
              :isFlag="true"
              falseLabel="삭제물질 포함"
              trueLabel="삭제물질 포함"
              name="delFlag"
              addClasses="tableCheckbox-top"
              v-model="delFlag"
              @input="getDetail"
            />
            <!-- <c-btn label="환경부-화학물질정보 연동 추가" v-if="editable" icon="add" @btnClicked="addApiRow" /> -->
            <c-btn label="추가" v-if="editable" icon="add" @btnClicked="addrow" />
            <c-btn label="삭제" v-if="editable" icon="remove" @btnClicked="removeRow" />
            <c-btn label="저장" v-if="editable" icon="save" @btnClicked="saveSubstance" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props,col }">
          <template>
            <q-chip
              v-if="col.name==='msdslink' && props.row.mttrid != ''"
              outline square
              color="blue"
              :clickable="true"
              @click.stop="() => msdslink(props.row)"
              text-color="white">
              MSDS
            </q-chip>
          </template>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="innertable"
        tableId="innertable"
        title="구성성분별 규제사항"
        class="topcolor-orange"
        :columns="grid2.columns"
        :data="grid2.data"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
        :hideBottom="true"
        :editable="false"
        :gridHeight="innertableHeight"
      />
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  components: {
  },
  name: 'chem-components',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        mdmChemReviewId: '',  // 사전검토 일련번호
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        usageCd: '',  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        msdsNo: '',  // MSDS번호
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고 환산계수
        inConversonUnitCd: null,  // 입고 환산단위
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '',  // 미사용사유
        preMdmChemMaterialId: '',  // 이전화학자재 일련번호
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'kr',
      editable: true,
      grid2: {
        columns: [
        ],
        data: [],
      },
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        useFlag: 'Y',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      innertableHeight: '',
      headerUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      nationData: [],
      nations: [],
      delFlag: 'N',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    this.headerUrl = selectConfig.mdm.mam.chem.header.url;
    this.getHeader();
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.innertableHeight = (window.innerHeight - 500) + 'px';
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.mam.chem.substance.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.substance.save.url;
      this.deleteUrl = transactionConfig.mdm.mam.chem.substance.delete.url;
      this.reglistUrl = selectConfig.mdm.mam.chem.substance.regulate.url;
      this.tableHeaderSet();
      this.getDetail();
    },  
    tableHeaderSet() {
      this.grid.columns = [
        // {
        //   name: 'chemNmKr',
        //   field: 'chemNmKr',
        //   label: '화학물질명(KOR)',
        //   align: 'left',
        //   style: 'width:350px',
        //   sortable: false,
        // },
        {
          name: 'chemNmEn',
          field: 'chemNmEn',
          label: '화학물질명(ENG)',
          align: 'left',
          sortable: false,
          style: 'width:350px',
        },
        {
          name: 'casNo',
          field: 'casNo',
          label: 'CAS No.',
          align: 'center',
          style: 'width:120px',
          sortable: false,
        },
        {
          name: 'molecularFormula',
          field: 'molecularFormula',
          label: '분자식',
          align: 'center',
          style: 'width:120px',
          sortable: false,
        },
        {
          label: '함유량(%)',
          align: 'center',
          child: [
            {
              name: 'limitDisplay',
              field: 'limitDisplay',
              label: '표시',
              align: 'center',
              style: 'width:120px',
              type: 'text',
              sortable: false,
            },
            {
              name: 'limitHigh',
              field: 'limitHigh',
              label: '상한',
              align: 'center',
              style: 'width:90px',
              type: 'number',
              sortable: false,
            },
            {
              name: 'limitLow',
              field: 'limitLow',
              label: '하한',
              align: 'center',
              style: 'width:90px',
              type: 'number',
              sortable: false,
            },
            {
              name: 'limitRepval',
              field: 'limitRepval',
              label: '대표값',
              align: 'center',
              style: 'width:80px',
              type: 'number',
              sortable: false,
            },
          ]
        },
        {
          name: 'businessSecFlag',
          field: 'businessSecFlag',
          label: '영업비밀',
          align: 'center',
          style: 'width:80px',
          sortable: false,
          type: 'check',
          true: 'Y',
          false: 'N'
        },
        {
          name: 'delFlag',
          field: 'delFlag',
          label: '삭제여부',
          align: 'center',
          style: 'width:80px',
          sortable: false,
        },
        {
          label: '추가/삭제 이력',
          align: 'center',
          child: [
            {
              name: 'regDtStrTime',
              field: 'regDtStrTime',
              label: '추가일시',
              align: 'center',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'regUserName',
              field: 'regUserName',
              label: '추가사용자',
              align: 'center',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'chgDtStrTime',
              field: 'chgDtStrTime',
              label: '삭제일시',
              align: 'center',
              style: 'width:150px',
              sortable: false,
            },
            {
              name: 'chgUserName',
              field: 'chgUserName',
              label: '삭제사용자',
              align: 'center',
              style: 'width:150px',
              sortable: false,
            },
          ]
        },
      ]
    },
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {headerType: 'check'};
      this.$http.request((_result) => {
        let _items = {
          name: 'chemNmEn',
          field: 'chemNmEn',
          label: '화학물질명(ENG)',
          align: 'left',
          style: 'width:220px',
          sortable: false,
        }
        this.grid2.columns.push(_items);
        this.$_.forEach(_result.data, _item => {
          this.grid2.columns.push(_item);
        })
        this.getLaw();
      },);
    },
    getLaw() {
      this.$http.url = this.reglistUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.grid2.data = _result.data;

        this.$_.forEach(_result.data, _item => {
          this.nationData.push({
            chemNmKr: _item.chemNmKr,
            chemNmEn: _item.chemNmKr,
            casNo: _item.casNo,
            nations: this.$_.filter(this.nations, {casNo: _item.casNo}),
          })
        })
      },);
    },
    getDetail() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId, delFlag: this.delFlag};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrow() {
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/chem/chemBasePop.vue'}`);
      this.popupOptions.title = '화학물질';
      this.popupOptions.width = '60%';
      this.popupOptions.param = {
        type: 'multiple',
        isDetail: true,
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeChemBasePopup;
    },
    closeChemBasePopup(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data && _data.length > 0) {
        this.$_.forEach(_data, item => {
          if (this.$_.findIndex(this.grid.data, { mdmChemId: item.mdmChemId }) === -1) {
            this.grid.data.splice(0, 0, {
              mdmChemMaterialId: this.popupParam.mdmChemMaterialId,
              mdmChemId: item.mdmChemId,
              casNo: item.casNo,
              chemNmKr: item.chemNmKr,
              chemNmEn: item.chemNmEn,
              limitHigh: 0,
              limitLow: 0,
              limitRepval: 0,
              businessSecFlag: 'N',
              delFlag: 'N',
              molecularFormula: item.molecularFormula,
              boilingPoint: item.boilingPoint,
              cmrFlag: item.cmrFlag,
              maleficenceForm: item.maleficenceForm,
              maleficenceValue: item.maleficenceValue,
              maleficenceType: item.maleficenceType,
              maleficenceGrade: item.maleficenceGrade,
              hrCodeGrade: item.hrCodeGrade,
              rcode: item.rcode,  // 위험문구(R-code)
              hcode: item.hcode,  // 유해위험문구(H-code)
              // mttrid: item.mttrid,
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            })
          }
        })
      }
    },
    // msdslink(row) {
    //   window.open('https://msds.kosha.or.kr/MSDSInfo/kcic/msdsdetail.do?chem_id='+row.mttrid+'&viewType=msds','msdspop','width=1280, height=768, menubar=no, status=no, toolbar=no');
    // },
    // addApiRow() {
    //   this.popupOptions.target = () => import(`${'@/pages/common/openapi/materialApiPop.vue'}`);
    //   this.popupOptions.title = '환경부-화학물질정보 연동 검색';
    //   this.popupOptions.width = '50%';
    //   this.popupOptions.param = {
    //     type: 'multiple'
    //   }
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopup;
    // },
    // closePopup(_data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if(_data.length !== undefined) {
    //     this.$_.forEach(_data, item => {
    //       if (this.$_.findIndex(this.grid.data, { casNo: item.casno }) === -1) {
    //         this.grid.data.splice(0, 0, {
    //           editFlag: 'C',
    //           mdmChemMaterialId: this.param.mdmChemMaterialId,
    //           mdmMaterialSubstancesId: uid(),
    //           casNo: item.casno ? item.casno : '',
    //           chemNmKr: item.mttrnmkor ? item.mttrnmkor : '',
    //           chemNmEn: item.mttrnmeng ? item.mttrnmeng : '',
    //           limitLabel: '',
    //           limitHigh: '',
    //           limitLow: '',
    //           limitRepval: '',
    //           businessSecFlag: 'N',
    //           cmrFlag: 'N',
    //           boilingPoint: null,
    //           hrCodeGrade: null,
    //           maleficenceForm: null,
    //           maleficenceType: null,
    //           maleficenceValue: '',
    //           molecularFormula: item.molecform ? item.molecform : '',
    //           mttrid: item.chemId ? item.chemId : '',  // 환경부데이터 물질ID(CHEM ID)
    //           regUserId: this.$store.getters.user.userId,
    //         })
    //       }
    //     })
    //   }
    // },
    saveSubstance() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.getDetail();
              this.getLaw();
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRow() {
      let selectData = this.$refs['substanceTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(selectData, item => {
              item.delFlag = 'Y';
              item.chgUserId = this.$store.getters.user.userId;
            });
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getDetail();
              this.getLaw();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['substanceTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>

<style lang="sass">
.substanceTableCss .lanauageFlag
  margin: 0 -1.2em 0 -0.1em !important
.substanceTableCss .q-tab__label
  font-size: 0.80rem
  font-weight: 600
.substanceTableCss .q-tab
  height: 36px
.lawchemChip
  margin-right: 5px !important
  cursor: pointer
.lawchemChiptd
  line-height: 28px !important
.chemavatar
  width: 22px !important
  height: 22px !important
  cursor: pointer
</style>